import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material";

import Admin from "./pages/admin/Admin";

import NewHomePage from "./NewHomePageComponents/NewHomePage.jsx";

import { BrowserRouter, Routes, Route } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6C91C2",
    },
    secondary: {
      main: "#081446",
    },
    tertiary: {
      main: "#2CAFEB",
    },
    quaternary: {
      main: "#BBDCEB",
    },
    quinary: {
      main: "#F2F3F4",
    },
    senary: {
      main: "#060f33",
    }
  },
  typography: {
    fontFamily: [
      "Catamaran",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
    ].join(","),
    h1: {
      fontWeight: 900,
      fontSize: "3em",
      textTransform: "uppercase",
      textAlign: "center",
      textFillColor: "transparent",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
    },
    h2: {
      fontWeight: 500,
      textAlign: "center",
      fontSize: "3em",
    },
    h3: {
      fontSize: "2.5em",
      fontWeight: 600,
      textAlign: "center",
    },
    h4: {
      fontWeight: 500,
      textAlign: "center",
      fontSize: "1.3em",
    },

    body1: {
      fontWeight: 600,
      fontSize: "1.1em",
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.9em",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route index element={<NewHomePage />} />
          
          <Route path="super-secret-admin" element={<Admin />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
