import { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import anime from "animejs";
import StatsMap from "../NewHomePagePhotos/Whitebg1.png";
import TypeWriterText from "./TypeWriterText";

const StatBox = styled(Box)(({ theme }) => ({
  boxShadow: theme.shadows[3],
  padding: theme.spacing(2, 3),
  backgroundColor: "#F2F3F4",
  zIndex: 20,
  textAlign: "center",
  opacity: 0,
}));

const formatNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const FavStat = () => {
  const theme = useTheme();
  const isXsOrSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdOrLgOrXl = useMediaQuery(theme.breakpoints.up("md"));

  const [animationsPlayed, setAnimationsPlayed] = useState(false);
  const sectionRef = useRef(null);
  const statRefs = useRef([useRef(null), useRef(null), useRef(null)]);
  const textRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !animationsPlayed) {
            setAnimationsPlayed(true);

            anime({
              targets:
                statRefs.current[0].current.querySelector(".stat-number"),
              innerHTML: [0, 987000],
              easing: "linear",
              round: 1,
              duration: 2000,
              update: function (anim) {
                const value = anim.animations[0].currentValue;
                statRefs.current[0].current.querySelector(
                  ".stat-number"
                ).innerHTML = formatNumber(value);
              },
            });

            anime({
              targets:
                statRefs.current[1].current.querySelector(".stat-number-2"),
              innerHTML: [0, 30000],
              easing: "linear",
              round: 1,
              duration: 2000,
              update: function (anim) {
                const value = anim.animations[0].currentValue;
                statRefs.current[1].current.querySelector(
                  ".stat-number-2"
                ).innerHTML = formatNumber(value);
              },
            });

            anime({
              targets:
                statRefs.current[2].current.querySelector(".stat-number-3"),
              innerHTML: [0, 80000],
              easing: "linear",
              round: 1,
              duration: 2000,
              update: function (anim) {
                const value = anim.animations[0].currentValue;
                statRefs.current[2].current.querySelector(
                  ".stat-number-3"
                ).innerHTML = formatNumber(value);
              },
            });

            statRefs.current.forEach((ref, index) => {
              anime({
                targets: ref.current,
                translateY: [100, 0],
                opacity: [0, 1],
                duration: 1000,
                easing: "easeOutExpo",
                delay: 400 + index * 200,
              });
            });

            anime({
              targets: textRef.current,
              opacity: [0, 1],
              duration: 1000,
              easing: "easeOutExpo",
            });

            observer.disconnect();
          }
        });
      },
      { threshold: 0.3 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [animationsPlayed]);

  return (
    <Box sx={{ textAlign: "center" }} ref={sectionRef}>
      <Box
        sx={{
          height: "70vh",
          width: "100vw",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: isXsOrSm ? "125vh" : isMdOrLgOrXl ? "70vh" : "120vh",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${StatsMap})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -2,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: -1,
          }}
        />
        <Container sx={{ mx: 2 }}>
          <Grid container spacing={2} justifyContent="center" sx={{ mt: 1 }}>
            <Grid item xs={12} sx={{ my: 1 }}>
              <Box
                sx={{
                  borderRadius: 1,
                  display: "block",
                  marginX: { xs: 2, md: 0 },
                }}
                ref={textRef}
              >
                <TypeWriterText />
              </Box>
            </Grid>
            <Grid container item xs={12} justifyContent="center" spacing={2}>
              <Grid item xs={10} sm={8} md={4} lg={3}>
                <StatBox
                  ref={statRefs.current[0]}
                  sx={{ px: 4, py: 3, borderRadius: 3 }}
                >
                  <Typography
                    variant="h3"
                    className="stat-number"
                    sx={{
                      fontSize: { xs: "1.5rem", sm: "1.6rem", md: "2.6rem" },
                      fontWeight: "bold",
                      color: "secondary.main",
                    }}
                  >
                    987,000
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ my: 1, color: "primary.main" }}
                  >
                    Boats currently Listed
                  </Typography>
                </StatBox>
              </Grid>
              <Grid item xs={10} sm={8} md={4} lg={3}>
                <StatBox
                  ref={statRefs.current[1]}
                  sx={{ px: 4, py: 3, borderRadius: 3 }}
                >
                  <Typography
                    variant="h3"
                    className="stat-number-2"
                    sx={{
                      fontSize: { xs: "1.5rem", sm: "1.6rem", md: "2.6rem" },
                      fontWeight: "bold",
                      color: "secondary.main",
                    }}
                  >
                    30,000
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ my: 1, color: "primary.main" }}
                  >
                    Makes and Models
                  </Typography>
                </StatBox>
              </Grid>
              <Grid item xs={10} sm={8} md={4} lg={3}>
                <StatBox
                  ref={statRefs.current[2]}
                  sx={{ px: 4, py: 3, borderRadius: 3 }}
                >
                  <Typography
                    variant="h3"
                    className="stat-number-3"
                    sx={{
                      fontSize: { xs: "1.5rem", sm: "1.6rem", md: "2.6rem" },
                      fontWeight: "bold",
                      color: "secondary.main",
                    }}
                  >
                    80,000
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ my: 1, color: "primary.main" }}
                  >
                    Boats listed monthly
                  </Typography>
                </StatBox>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default FavStat;
