import React, { useRef, useEffect, useState } from "react";
import { Box, Typography, Button, Container, Grid } from "@mui/material";
import LandingImg from "../NewHomePagePhotos/LandingImg/LandingImg.png";
import anime from "animejs/lib/anime.es.js";
import ContactModal from "./ContactModal";

const FavHero = () => {
  const textRef7 = useRef(null);
  const textRef8 = useRef(null);
  const textRef9 = useRef(null);
  const textRef10 = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    anime({
      targets: textRef7.current,
      scale: [0.7, 1],
      opacity: [0, 1],
      duration: 1400,
      easing: "easeOutQuad",
      delay: 500,
    });

    anime({
      targets: textRef8.current,
      scale: [0.6, 1],
      opacity: [0, 1],
      duration: 800,
      easing: "easeOutQuad",
      delay: 950,
    });

    anime({
      targets: textRef9.current,
      opacity: [0, 1],
      translateY: [-20, 0],
      easing: "easeInOutQuad",
      duration: 1000,
      delay: 500,
    });

    anime({
      targets: textRef10.current,
      opacity: [0, 1],
      translateY: [-20, 0],
      easing: "easeInOutQuad",
      duration: 1000,
      delay: 1000,
    });
  }, []);

  return (
    <Box
      component="section"
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
        background: "transparent",
      }}
    >
      <Grid container spacing={0} sx={{ height: "100%" }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            zIndex: 2,
            p: 4,
            backgroundColor: "rgba(8, 20, 70, 0.8)",
          }}
        >
          <Box
            sx={{
              maxWidth: "600px",
              mx: "auto",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h2"
              gutterBottom
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "2rem", md: "3rem", lg: "4rem" },
                color: "primary.main",
                textAlign: "center",
                justifyContent: "center",
              }}
              ref={textRef7}
            >
              <span style={{ fontWeight: "bold" }}>WE</span>
              <span style={{ fontWeight: "normal" }}>FIND</span>
              <span style={{ fontWeight: "bold" }}>YOUR</span>
              <span style={{ fontWeight: "normal" }}>BOAT</span>
            </Typography>
            <Container sx={{ textAlign: "center" }}>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  color: "quinary.main",
                  my: 2,
                  fontSize: { xs: "1rem", md: "1.5rem", lg: "1.5rem" },
                }}
                ref={textRef8}
              >
                Artificial Intelligence Driven Boat Market Data Solutions
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ my: 2 }}
                ref={textRef9}
                onClick={handleModalOpen}
              >
                Contact Us
              </Button>
            </Container>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: { xs: "none", md: "block" },
            position: "relative",
            zIndex: 1,
            backgroundColor: "transparent",
          }}
        >
          <svg
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              height: "100%",
              width: "100%",
            }}
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <polygon
              fill="currentColor"
              points="0,0 25,0 0,100"
              style={{ fill: "rgba(8, 20, 70, 0.8)" }}
            />
          </svg>
        </Grid>
      </Grid>
      <Box
        component="img"
        src={LandingImg}
        alt="Hero3 boat"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center",
          zIndex: -1,
        }}
      />
      <ContactModal open={modalOpen} handleClose={handleModalClose} />
    </Box>
  );
};

export default FavHero;
