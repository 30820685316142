import { useRef, useEffect, useState, useCallback } from "react";
import { Box, Typography, Grid, CircularProgress } from "@mui/material";
import anime from "animejs";
import { useDropzone } from "react-dropzone";
import { VictoryChart, VictoryArea, VictoryAxis } from "victory";
import { BaseAPIUrl } from "../settings";
import Placeholder from "../NewHomePagePhotos/placeholder.jpg";
import DemoPlaceholder from "../NewHomePagePhotos/placeholder.jpg";
import Boat1 from "../NewHomePagePhotos/1A.png";
import Boat2 from "../NewHomePagePhotos/2A.png";
import Boat3 from "../NewHomePagePhotos/3A.png";
import Boat4 from "../NewHomePagePhotos/4A.png";
import Boat5 from "../NewHomePagePhotos/5A.jpg";
import Boat6 from "../NewHomePagePhotos/6A.jpg";
import Boat7 from "../NewHomePagePhotos/7A.jpg";
import Boat8 from "../NewHomePagePhotos/8A.jpg";
import Boat9 from "../NewHomePagePhotos/9A.jpg";
import Boat10 from "../NewHomePagePhotos/10A.jpg";
import "./PhotoSelector.css";
import Background from "../NewHomePagePhotos/Whitebg1.png";

const Demo1 = () => {
  const textRef1 = useRef(null);
  const textRef2 = useRef(null);
  const textRef3 = useRef(null);
  const textRef3A = useRef(null);
  const textRef4 = useRef(null);
  const textRef5 = useRef(null);
  const textRef6 = useRef(null);
  const textRef7 = useRef(null);
  const textRef8 = useRef(null);
  const textRef9 = useRef(null);
  const textRef10 = useRef(null);
  const componentRef = useRef(null);
  const [imagePreviewSrc, setImagePreviewSrc] = useState(Placeholder);
  const [hoveredImageSrc, setHoveredImageSrc] = useState(null);
  const [imageSrcs, setImageSrcs] = useState(Array(6).fill(DemoPlaceholder));
  const [nextImageSrcs, setNextImageSrcs] = useState([]);
  const [extraImageIndex, setExtraImageIndex] = useState(0);
  const [heatmapSrc, setHeatmapSrc] = useState(null);
  const [lengthData, setLengthData] = useState([]);
  const [priceData, setPriceData] = useState([]);
  const [hideSearchResults, setHideSearchResults] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      previewFile(file);
      setHideSearchResults(false);
      getBase64(file);
    },
  });

  const toDataUrl = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  function handleSampleImageClick(url) {
    setImagePreviewSrc(Placeholder);
    window.scrollBy(0, 500);
    toDataUrl(url, (myBase64) => {
      setHideSearchResults(false);
      const fd = {
        image: myBase64.replace("data:", "").replace(/^.+,/, ""),
        page: 1,
      };
      getImages(fd);
    });
  }

  function previewFile(file) {
    if (file) {
      const reader = new FileReader();
      reader.onload = function () {
        setImagePreviewSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }

  const getImages = (fd) => {
    setIsLoading(true);
    fetch(`${BaseAPIUrl}/search/image-search-with-insights`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(fd),
    })
      .then((res) => res.json())
      .then((data) => {
        let newImageSrcs = data.listings
          .filter(
            (listing) =>
              listing.image_url && !listing.image_url.includes("img.youtube")
          )
          .map((listing) => listing.image_url);

        setImageSrcs(newImageSrcs.slice(0, 6));
        setNextImageSrcs(newImageSrcs.slice(6));
        setHeatmapSrc(`data:image/jpeg;base64,${data.heatmap}`);
        setLengthData(data.boats_by_length);
        setPriceData(data.boats_by_price);
        setIsLoading(false);
        animateMarketInsightsAndSimilarBoats();
      });
  };

  function getBase64(imageFile) {
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onload = function (e) {
      const img = document.createElement("img");
      img.src = e.target.result;
      img.onload = function () {
        const MAX_WIDTH = 500;
        const MAX_HEIGHT = 500;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height = height * (MAX_WIDTH / width);
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width = width * (MAX_HEIGHT / height);
            height = MAX_HEIGHT;
          }
        }
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        const dataurl = canvas.toDataURL(imageFile.type);
        const fd = {
          image: dataurl.replace("data:", "").replace(/^.+,/, ""),
          page: 1,
        };
        getImages(fd);
      };
    };
  }

  function handleImageLoadError(index) {
    let newImageSrcs = imageSrcs;
    let replacementIndex = extraImageIndex;
    setExtraImageIndex((extraImageIndex) => extraImageIndex + 1);
    newImageSrcs[index] = nextImageSrcs[replacementIndex];
    setImageSrcs([...newImageSrcs]);
  }

  const animateMarketInsightsAndSimilarBoats = useCallback(() => {
    if (!hideSearchResults) {
      anime({
        targets: textRef9.current,
        translateY: [110, 0],
        opacity: [0, 1],
        duration: 900,
        easing: "easeOutExpo",
        delay: 10,
      });

      anime({
        targets: textRef10.current,
        translateY: [110, 0],
        opacity: [0, 1],
        duration: 900,
        easing: "easeOutExpo",
        delay: 0,
      });

      anime({
        targets: textRef6.current,
        translateY: [110, 0],
        opacity: [0, 1],
        duration: 900,
        easing: "easeOutExpo",
        delay: 0,
      });

      anime({
        targets: textRef8.current,
        scale: [0.7, 1],
        opacity: [0, 1],
        duration: 800,
        easing: "easeOutQuad",
        delay: 0,
      });
    }
  }, [hideSearchResults]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            anime({
              targets: textRef1.current,
              translateY: [110, 0],
              opacity: [0, 1],
              duration: 900,
              easing: "easeOutExpo",
              delay: 50,
            });

            anime({
              targets: textRef2.current,
              scale: [0.7, 1],
              opacity: [0, 1],
              duration: 800,
              easing: "easeOutQuad",
              delay: 600,
            });

            anime({
              targets: textRef3.current,
              scale: [0.7, 1],
              opacity: [0, 1],
              duration: 800,
              easing: "easeOutQuad",
              delay: 350,
            });

            anime({
              targets: textRef3A.current,
              scale: [0.7, 1],
              opacity: [0, 1],
              duration: 800,
              easing: "easeOutQuad",
              delay: 500,
            });

            anime({
              targets: textRef4.current,
              translateY: [110, 0],
              opacity: [0, 1],
              duration: 900,
              easing: "easeOutExpo",
              delay: 250,
            });

            anime({
              targets: textRef5.current,
              scale: [0.7, 1],
              opacity: [0, 1],
              duration: 800,
              easing: "easeOutQuad",
              delay: 900,
            });

            anime({
              targets: textRef6.current,
              translateY: [110, 0],
              opacity: [0, 1],
              duration: 900,
              easing: "easeOutExpo",
              delay: 1200,
            });

            anime({
              targets: textRef7.current,
              scale: [0.8, 1],
              opacity: [0, 1],
              duration: 1400,
              easing: "easeOutQuad",
              delay: 1050,
            });

            observer.unobserve(componentRef.current);
            componentRef.current.style.visibility = "visible";
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentComponent = componentRef.current;

    if (currentComponent) {
      observer.observe(currentComponent);
    }

    return () => {
      // Use the captured value in the cleanup function
      if (currentComponent) {
        observer.unobserve(currentComponent);
      }
    };
  }, []);

  useEffect(() => {
    if (!hideSearchResults) {
      animateMarketInsightsAndSimilarBoats();
    }
  }, [hideSearchResults, animateMarketInsightsAndSimilarBoats]);

  const Boats = [
    Boat1,
    Boat2,
    Boat3,
    Boat4,
    Boat5,
    Boat6,
    Boat7,
    Boat8,
    Boat9,
    Boat10,
  ];

  const handleMouseEnterReel = () => {
    if (hoveredImageSrc) {
      setImagePreviewSrc(hoveredImageSrc);
    }
  };

  const handleMouseLeaveReel = () => {
    setImagePreviewSrc(Placeholder);
  };

  return (
    <Box
      ref={componentRef}
      sx={{
        position: "relative",
        width: "100%",
        px: 0,
        py: 10,
        visibility: "hidden",
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${Background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -2,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(255,255,255,0.5)",
          backdropFilter: "blur(5px)",
          zIndex: -1,
        }}
      />
      <Box
        sx={{
          zIndex: 1,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          paddingX: { xs: 0, md: 2 },
          paddingY: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={5}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  color: "secondary.main",
                  justifyContent: "flex-start",
                  padding: { xs: 1, md: 3 },
                  m: { xs: 2, md: 6 },
                  textAlign: "center",
                  backgroundColor: "rgba(255,255,255,0.3)",
                  borderRadius: 6,
                  height: "100%",
                }}
              >
                <Typography
                  variant="h3"
                  ref={textRef1}
                  sx={{ my: 1, fontWeight: "bold", opacity: 0 }}
                >
                  OUR TECHNOLOGY:
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    color: "secondary.main",
                    my: 5,
                    padding: 3,
                    textAlign: {
                      xs: "center",
                      sm: "center",
                      md: "center",
                      lg: "center",
                      xl: "justify",
                    },
                    fontWeight: "bold",
                    opacity: 0,
                  }}
                  ref={textRef3}
                >
                  We have created our own Proprietary and Artificial
                  Intelligence driven search engine, capable of searching over
                  700,000 boats currently for sale around the world.
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    color: "secondary.main",
                    my: 2,
                    padding: 3,
                    textAlign: {
                      xs: "center",
                      sm: "center",
                      md: "center",
                      lg: "center",
                      xl: "justify",
                    },
                    fontWeight: "bold",
                    opacity: 0,
                  }}
                  ref={textRef3A}
                >
                  Our technology allows us to quickly search using images and
                  requirements to find ideal matches no matter where they are.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingY: 4,
                m: { xs: 2, md: 6 },
                textAlign: "center",
                backgroundColor: "rgba(255,255,255,0.3)",
                borderRadius: 5,
              }}
            >
              <Box>
                <Typography
                  variant="h3"
                  ref={textRef4}
                  sx={{
                    color: "secondary.main",
                    fontWeight: "bold",
                    py: 3,
                    opacity: 0,
                  }}
                >
                  TRY IT OUT:
                </Typography>
                <Box
                  sx={{
                    maxWidth: { xs: 310, sm: 400, md: 450 },
                    margin: "auto",
                    backgroundImage: `url(${imagePreviewSrc})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    boxShadow: {
                      xs: "none",
                      md: "0px 4px 20px rgba(0, 0, 0, 0.5)",
                    },
                    transition:
                      "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.05)",
                      boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.8)",
                    },
                    opacity: 0,
                  }}
                  ref={textRef2}
                >
                  <Box
                    {...getRootProps()}
                    sx={{
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      height: { xs: 170, sm: 280, md: 300 },
                    }}
                  >
                    <input {...getInputProps()} style={{ display: "none" }} />
                  </Box>
                </Box>
              </Box>

              <Box sx={{ textAlign: "center", py: 2 }}>
                <Typography
                  variant="h4"
                  ref={textRef5}
                  sx={{
                    color: "secondary.main",
                    my: 3,
                    fontWeight: "bold",
                    opacity: 0,
                  }}
                >
                  Or select from our sample images:
                </Typography>
                <Box
                  className="logos"
                  ref={textRef7}
                  sx={{ opacity: 0, backgroundColor: "#fff" }}
                  onMouseEnter={handleMouseEnterReel}
                  onMouseLeave={handleMouseLeaveReel}
                >
                  <div className="logos-slide">
                    {Boats.map((Boat, index) => (
                      <img
                        src={Boat}
                        alt={`Boat ${(index % 6) + 1}`}
                        key={index}
                        className="hoverScale"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSampleImageClick(Boat)}
                        onMouseEnter={() => {
                          setHoveredImageSrc(Boat);
                          setImagePreviewSrc(Boat);
                          anime({
                            targets: textRef2.current,
                            opacity: [0, 1],
                            duration: 500,
                            easing: "easeOutQuad",
                          });
                        }}
                        onMouseLeave={() => {
                          setHoveredImageSrc(null);
                        }}
                      />
                    ))}
                    {Boats.map((Boat, index) => (
                      <img
                        src={Boat}
                        alt={`Boat ${index + Boats.length + 1}`}
                        key={index + Boats.length}
                        className="hoverScale"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSampleImageClick(Boat)}
                        onMouseEnter={() => {
                          setHoveredImageSrc(Boat);
                          setImagePreviewSrc(Boat);
                          anime({
                            targets: textRef2.current,
                            opacity: [0, 1],
                            duration: 1500,
                            easing: "easeOutQuad",
                          });
                        }}
                        onMouseLeave={() => {
                          setHoveredImageSrc(null);
                          anime({
                            targets: textRef2.current,
                            opacity: [1, 0],
                            duration: 300,
                            easing: "easeOutQuad",
                          });
                        }}
                      />
                    ))}
                  </div>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Box
              sx={{
                color: "secondary.main",
                justifyContent: "flex-start",
                padding: 3,
                mx: { xs: 0, md: 6 },
                mt: { xs: 2, md: 6 },
                textAlign: "center",
                backgroundColor: "rgba(255,255,255,0.3)",
                borderRadius: 6,
              }}
              hidden={hideSearchResults}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Box>
                    <Typography
                      variant="h3"
                      sx={{
                        color: "secondary.main",
                        my: 2,
                        fontWeight: "bold",
                      }}
                      ref={textRef9}
                    >
                      MARKET INSIGHTS:
                    </Typography>
                  </Box>
                  <Grid container spacing={1} ref={textRef6}>
                    {heatmapSrc ? (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            textAlign: "center",
                            width: "90%",
                            margin: "auto",
                            padding: 1,
                            backgroundColor: "white",
                            borderRadius: 4,
                            overflow: "hidden",
                            "& img": {
                              transition: "transform 0.3s ease-in-out",
                            },
                            "& img:hover": {
                              transform: "scale(1.05)",
                            },
                          }}
                        >
                          <Typography
                            variant="h5"
                            color="secondary"
                            sx={{ marginBottom: 0.5 }}
                          >
                            Location Distribution
                          </Typography>
                          <img
                            src={heatmapSrc}
                            style={{ width: "100%" }}
                            alt="Heatmap"
                          />
                        </Box>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 250,
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      </Grid>
                    )}
                    {lengthData.length > 0 ? (
                      <Grid item xs={12} sm={6}>
                        <Box
                          sx={{
                            textAlign: "center",
                            marginTop: 5,
                            padding: 1,
                            width: "90%",
                            margin: "auto",
                            backgroundColor: "white",
                            borderRadius: 4,
                          }}
                        >
                          <Typography
                            variant="h5"
                            color="secondary"
                            sx={{ marginBottom: 0.5 }}
                          >
                            Length Distribution
                          </Typography>
                          <VictoryChart>
                            <VictoryAxis
                              style={{
                                axisLabel: {
                                  fontSize: 15,
                                  padding: 30,
                                  fill: "black",
                                },
                                tickLabels: {
                                  fontSize: 10,
                                  padding: 5,
                                  fill: "black",
                                },
                                labels: {
                                  fontSize: 5,
                                  padding: 5,
                                  fill: "black",
                                },
                              }}
                              fixLabelOverlap={true}
                              label="Length (m)"
                            />
                            <VictoryArea
                              interpolation="cardinal"
                              style={{
                                data: {
                                  stroke: "rgb(1, 151, 221)",
                                  fill: "rgb(1, 151, 221)",
                                },
                                parent: { border: "1px solid white" },
                                labels: { fill: "black" },
                              }}
                              data={lengthData}
                            />
                          </VictoryChart>
                        </Box>
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={6}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 250,
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      </Grid>
                    )}
                    {priceData.length > 0 ? (
                      <Grid item xs={12} sm={6}>
                        <Box
                          sx={{
                            textAlign: "center",
                            marginTop: 5,
                            padding: 1,
                            width: "90%",
                            margin: "auto",
                            backgroundColor: "white",
                            borderRadius: 4,
                          }}
                        >
                          <Typography
                            variant="h5"
                            color="secondary"
                            sx={{ marginBottom: 0.5 }}
                          >
                            Price Distribution
                          </Typography>
                          <VictoryChart>
                            <VictoryAxis
                              style={{
                                axisLabel: {
                                  fontSize: 15,
                                  padding: 30,
                                  fill: "black",
                                },
                                tickLabels: {
                                  fontSize: 10,
                                  padding: 10,
                                  fill: "black",
                                  angle: -25,
                                },
                                labels: {
                                  fontSize: 5,
                                  padding: 5,
                                  fill: "black",
                                },
                              }}
                              fixLabelOverlap={true}
                              label="Price (USD)"
                            />
                            <VictoryArea
                              interpolation="cardinal"
                              style={{
                                data: {
                                  stroke: "rgb(1, 151, 221)",
                                  fill: "rgb(1, 151, 221)",
                                },
                                parent: { border: "1px solid white" },
                                labels: { fill: "black" },
                              }}
                              data={priceData}
                            />
                          </VictoryChart>
                        </Box>
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={6}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 250,
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Box hidden={hideSearchResults}>
                    <Box>
                      <Typography
                        variant="h3"
                        sx={{
                          color: "secondary.main",
                          fontWeight: "bold",
                          my: 2,
                        }}
                        ref={textRef10}
                      >
                        SIMILAR BOATS FOR SALE:
                      </Typography>
                      <Box>
                        <Box>
                          <Box
                            sx={{ maxHeight: "100%", mx: "auto" }}
                            ref={textRef8}
                          >
                            {isLoading ? (
                              <Grid
                                container
                                spacing={0}
                                sx={{
                                  "& .MuiGrid-item": {
                                    padding: "10px !important",
                                  },
                                }}
                              >
                                {imageSrcs.map((_, index) => (
                                  <Grid item xs={12} md={6} lg={4} key={index}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: 250,
                                      }}
                                    >
                                      <CircularProgress />
                                    </Box>
                                  </Grid>
                                ))}
                              </Grid>
                            ) : (
                              <Grid
                                className="similar-boats"
                                container
                                spacing={1}
                                sx={{
                                  "& .MuiGrid-item": {
                                    padding: "10px !important",
                                  },
                                }}
                              >
                                {imageSrcs.map((src, index) => (
                                  <Grid item xs={12} md={6} lg={4} key={index}>
                                    <Box
                                      sx={{
                                        height: 250,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        overflow: "hidden",
                                        "& img": {
                                          transition:
                                            "transform 0.3s ease-in-out",
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                        },
                                        "& img:hover": {
                                          transform: "scale(1.05)",
                                        },
                                      }}
                                    >
                                      <img
                                        onError={() =>
                                          handleImageLoadError(index)
                                        }
                                        src={src}
                                        alt={`Similar Boat ${index + 1}`}
                                      />
                                    </Box>
                                  </Grid>
                                ))}
                              </Grid>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Demo1;
