import { useRef, useEffect } from "react";
import anime from "animejs";
import { useTheme } from "@mui/material/styles";
import { Box, Grid, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AssessmentIcon from "@mui/icons-material/Assessment";
import InsightsIcon from "@mui/icons-material/Insights";

const CardsSmallScreen = () => {
  const theme = useTheme();
  const paperRefs = useRef([]);
  const textRef4 = useRef(null);

  useEffect(() => {
    anime({
      targets: paperRefs.current,
      translateY: [50, 0],
      opacity: [0, 1],
      duration: 800,
      easing: "easeOutCubic",
      delay: anime.stagger(300, { start: 100 }),
    });
  }, []);

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Grid
        container
        item
        spacing={0}
        ref={textRef4}
        sx={{
          mx: { xs: 1, sm: 4, md: 3, lg: 0 },
          borderRadius: 5,
        }}
      >
        {[
          "Search Engine",
          "Client Lifecycle",
          "Report Generation",
          "Market Insights",
        ].map((title, index) => (
          <Grid
            item
            xs={12}
            ref={(el) => (paperRefs.current[index] = el)}
            key={title}
            data-index={index}
            sx={{
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.4)",
              p: 1.5,
              my: 2,
              borderRadius: 5,
            }}
          >
            <Box className="paper">
              <Box className="side side1">
                <Typography
                  variant="h4"
                  sx={{
                    my: 1,
                    color: theme.palette.secondary.main,
                    fontWeight: "bold",
                    fontSize: { sm: "2.5rem" },
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  sx={{
                    mb: 3,
                    color: theme.palette.secondary.main,
                  }}
                >
                  {index === 0 && (
                    <SearchIcon sx={{ fontSize: "5rem", my: 2 }} />
                  )}
                  {index === 1 && (
                    <AutorenewIcon sx={{ fontSize: "5rem", my: 2 }} />
                  )}
                  {index === 2 && (
                    <AssessmentIcon sx={{ fontSize: "5rem", my: 2 }} />
                  )}
                  {index === 3 && (
                    <InsightsIcon sx={{ fontSize: "5rem", my: 2 }} />
                  )}
                </Typography>
              </Box>
              <Box className="side side2" sx={{ padding: 1.5 }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: "white",
                    fontSize: { xs: "0.8rem", sm: "1.1rem" },
                  }}
                >
                  {title === "Search Engine" &&
                    "Get access to our proprietary Artificial Intelligence driven search engine. Search over 80 sites using images and requirements to find the best boats for your clients."}
                  {title === "Client Lifecycle" &&
                    "Manage your clients through the entire lifecycle of the boat buying process. Keep track of requirements and preferences and be alerted when a boat that may suit them comes up for sale."}
                  {title === "Report Generation" &&
                    "Generate reports for your clients on the fly using our report builder. Add your own branding and customize the report to suit your needs."}
                  {title === "Market Insights" &&
                    "Market Insights are automatically generated and available to add to any of your clients' reports."}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CardsSmallScreen;
