import Demo1 from "./Demo1";
import FavHero from "./FavHero";
import FavStat from "./FavStat";
import KnowledgeEngineResponsive from "./KnowledgeEngineResponsive";
import FavPortal from "./FavPortal";
import Footer from "./footer";
import { Box } from "@mui/material";

const NewHomePage = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        overflowX: "hidden",
        flexDirection: "column",
      }}
    >
      <FavHero />
      <FavStat />
      <FavPortal />
      <Demo1 />
      <KnowledgeEngineResponsive />
      <Footer />
    </Box>
  );
};

export default NewHomePage;
