import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { motion } from "framer-motion";

const ContactModal = ({ open, handleClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    let formErrors = {};
    if (!formData.name) formErrors.name = "*required field";
    if (!formData.email) formErrors.email = "*required field";
    if (!formData.subject) formErrors.subject = "*required field";
    if (!formData.message) formErrors.message = "*required field";
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      // Submit the form
      console.log("Form submitted", formData);
      handleClose();
    }
  };

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1 },
  };

  const inputVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.2 },
    }),
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <motion.div
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.5, ease: "easeOut" }}
      >
        <Box
          sx={{
            position: "relative",
            width: { xs: "95vw", md: "50vw" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: { xs: "90vh", md: "auto" },
            overflowY: "auto",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              color: "secondary.main",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h2"
            gutterBottom
            sx={{ color: "secondary.main" }}
            component={motion.div}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.9 }}
          >
            Contact Us:
          </Typography>
          <motion.div
            custom={1}
            variants={inputVariants}
            initial="hidden"
            animate="visible"
          >
            <TextField
              fullWidth
              label="Your Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              helperText={
                errors.name && (
                  <span style={{ color: "red" }}>{errors.name}</span>
                )
              }
              margin="normal"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "secondary.main",
                  },
                  "&:hover fieldset": {
                    borderColor: "primary.main",
                  },
                },
              }}
            />
          </motion.div>
          <motion.div
            custom={2}
            variants={inputVariants}
            initial="hidden"
            animate="visible"
          >
            <TextField
              fullWidth
              label="Your Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              helperText={
                errors.email && (
                  <span style={{ color: "red" }}>{errors.email}</span>
                )
              }
              margin="normal"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "secondary.main",
                  },
                  "&:hover fieldset": {
                    borderColor: "primary.main",
                  },
                },
              }}
            />
          </motion.div>
          <motion.div
            custom={3}
            variants={inputVariants}
            initial="hidden"
            animate="visible"
          >
            <TextField
              fullWidth
              label="Subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              helperText={
                errors.subject && (
                  <span style={{ color: "red" }}>{errors.subject}</span>
                )
              }
              margin="normal"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "secondary.main",
                  },
                  "&:hover fieldset": {
                    borderColor: "primary.main",
                  },
                },
              }}
            />
          </motion.div>
          <motion.div
            custom={4}
            variants={inputVariants}
            initial="hidden"
            animate="visible"
          >
            <TextField
              fullWidth
              label="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              helperText={
                errors.message && (
                  <span style={{ color: "red" }}>{errors.message}</span>
                )
              }
              margin="normal"
              multiline
              rows={4}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "secondary.main",
                  },
                  "&:hover fieldset": {
                    borderColor: "primary.main",
                  },
                },
              }}
            />
          </motion.div>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              sx={{
                borderRadius: 10,
                textTransform: "none",
                background: "quinary.main",
                opacity: 0.9,
                mt: 2,
                "& .MuiTypography-root": {
                  marginRight: "0.5rem",
                },
                "&:hover": {
                  boxShadow: "0 0 10px #6C91C2",
                  backgroundColor: "primary.main",
                  color: "secondary.main",
                },
                "&:hover .MuiSvgIcon-root": {
                  transform: "translateX(5px)",
                  transition: "transform 0.3s ease-in-out",
                  color: "secondary.main",
                },
              }}
              component={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1 }}
            >
              <Typography sx={{ fontWeight: "bold" }}>SEND</Typography>
              <ArrowForwardIcon
                sx={{
                  transition: "transform 0.3s ease-in-out",
                  mb: 0.6,
                }}
              />
            </Button>
          </Box>
        </Box>
      </motion.div>
    </Modal>
  );
};

export default ContactModal;
