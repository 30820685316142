import React, { useState, useRef, useEffect } from "react";
import { Box, Grid, Typography, Container } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import anime from "animejs/lib/anime.es.js";
import "./FlippingCardsKnowledgeEngine.css";

const FlippingCardsKnowledgeEngine = () => {
  const [animationsPlayed, setAnimationsPlayed] = useState(false);
  const sectionRef = useRef(null);
  const textRef7 = useRef(null);
  const textRef8 = useRef(null);
  const textRef9 = useRef(null);
  const textRef10 = useRef(null);
  const textRef11 = useRef(null);
  const textRef12 = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !animationsPlayed) {
            setAnimationsPlayed(true);
            anime({
              targets: textRef7.current,
              translateY: [100, 0],
              opacity: [0, 1],
              duration: 1000,
              easing: "easeOutExpo",
              delay: 500,
            });
            anime({
              targets: textRef8.current,
              translateY: [100, 0],
              opacity: [0, 1],
              duration: 1000,
              easing: "easeOutExpo",
              delay: 700,
            });
            anime({
              targets: textRef9.current,
              translateY: [100, 0],
              opacity: [0, 1],
              duration: 1000,
              easing: "easeOutExpo",
              delay: 900,
            });
            anime({
              targets: textRef10.current,
              translateY: [100, 0],
              opacity: [0, 1],
              duration: 1000,
              easing: "easeOutExpo",
              delay: 1100,
            });
            anime({
              targets: textRef11.current,
              translateY: [100, 0],
              opacity: [0, 1],
              duration: 1000,
              easing: "easeOutExpo",
              delay: 1300,
            });
            anime({
              targets: textRef12.current,
              translateY: [100, 0],
              opacity: [0, 1],
              duration: 1000,
              easing: "easeOutExpo",
              delay: 1500,
            });
          }
        });
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [animationsPlayed]);

  return (
    <Container
      ref={sectionRef}
      sx={{
        py: 12,
        padding: "4em",
        color: "secondary.main",
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={4}>
          <Box className="maincontainer">
            <Box className="thecard">
              <Box
                className="thefront"
                ref={textRef7}
                sx={{
                  position: "relative",
                  border: "1px solid rgba(108, 145, 194, 0.5)",
                  borderRadius: "0.5em",
                  backgroundColor: "rgba(255,255,255,0.8)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "2em",
                  minHeight: "36em",
                  textAlign: "center",
                  opacity: 0,
                  "::before": {
                    content: '""',
                    position: "absolute",
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: "5px",
                    backgroundColor: "secondary.main",
                    borderRadius: "0.5em 0 0 0.5em",
                  },
                }}
              >
                <CloudUploadIcon
                  sx={{ fontSize: "10rem", color: "secondary.main" }}
                />
                <Typography variant="h4" sx={{ color: "secondary.main" }}>
                  Data Extrication
                </Typography>
              </Box>
              <Box className="theback">
                <Typography
                  variant="h5"
                  sx={{ color: "secondary.main", fontWeight: "bold" }}
                >
                  Import and Export of boat data from publicly available
                  sources. Our AI-driven data extraction engine can extract data
                  from 100s of sites.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Box className="maincontainer">
            <Box className="thecard">
              <Box
                className="thefront"
                ref={textRef9}
                sx={{
                  position: "relative",
                  border: "1px solid rgba(108, 145, 194, 0.5)",
                  borderRadius: "0.5em",
                  backgroundColor: "rgba(255,255,255,0.8)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "2em",
                  minHeight: "36em",
                  textAlign: "center",
                  opacity: 0,
                  "::before": {
                    content: '""',
                    position: "absolute",
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: "5px",
                    backgroundColor: "secondary.main",
                    borderRadius: "0.5em 0 0 0.5em",
                  },
                }}
              >
                <AutorenewIcon
                  sx={{ fontSize: "10rem", color: "secondary.main" }}
                />
                <Typography variant="h4" sx={{ color: "secondary.main" }}>
                  Price Modelling
                </Typography>
              </Box>
              <Box className="theback">
                <Typography
                  variant="h5"
                  sx={{ color: "secondary.main", fontWeight: "bold" }}
                >
                  Our knowledge engine includes millions of historic and current
                  listings, allowing pricing guidance and prediction.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Box className="maincontainer">
            <Box className="thecard">
              <Box
                className="thefront"
                ref={textRef11}
                sx={{
                  position: "relative",
                  border: "1px solid rgba(108, 145, 194, 0.5)",
                  borderRadius: "0.5em",
                  backgroundColor: "rgba(255,255,255,0.8)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "2em",
                  minHeight: "36em",
                  textAlign: "center",
                  opacity: 0,
                  "::before": {
                    content: '""',
                    position: "absolute",
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: "5px",
                    backgroundColor: "secondary.main",
                    borderRadius: "0.5em 0 0 0.5em",
                  },
                }}
              >
                <ManageSearchIcon
                  sx={{ fontSize: "10rem", color: "secondary.main" }}
                />
                <Typography variant="h4" sx={{ color: "secondary.main" }}>
                  Specification Verification
                </Typography>
              </Box>
              <Box className="theback">
                <Typography
                  variant="h5"
                  sx={{ color: "secondary.main", fontWeight: "bold" }}
                >
                  Using our knowledge engine with over 30,000 makes and models,
                  we can verify specifications and details of boats for sale, as
                  well as using images to verify the make and model is correct.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FlippingCardsKnowledgeEngine;
