import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./TypeWriterText.css"; // Importing CSS for complex animations

const TypeWriterText = () => {
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up("md"));

  const textArray = isMediumUp
    ? ["Global Market Insights"]
    : ["Global", "Market", "Insights"];

  return (
    <Box sx={{ color: "secondary.main", zIndex: 8 }}>
      <Box className="typewriter">
        {textArray.map((text, index) => (
          <Typography
            variant="h3"
            key={index}
            sx={{ ...typewriterTextStyle, animationDelay: `${2.5 * index}s` }}
          >
            {text}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default TypeWriterText;

const typewriterTextStyle = {
  fontWeight: 900,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textAlign: "center",
  mb: 2,
};
