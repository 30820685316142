import { useRef, useEffect, useState } from "react";
import anime from "animejs";
import { useTheme } from "@mui/material/styles";
import { Box, Grid, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

const KnowledgeEngineSmall = () => {
  const theme = useTheme();
  const [animationsPlayed, setAnimationsPlayed] = useState(false);
  const sectionRef = useRef(null);
  const paperRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !animationsPlayed) {
            setAnimationsPlayed(true);
            anime({
              targets: paperRefs.current,
              translateY: [50, 0],
              opacity: [0, 1],
              duration: 800,
              easing: "easeOutCubic",
              delay: anime.stagger(300, { start: 400 }),
            });
          }
        });
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [animationsPlayed]);

  return (
    <Box ref={sectionRef} sx={{ display: "flex", justifyContent: "center" }}>
      <Grid
        container
        spacing={0}
        sx={{
          justifyContent: "center",
          mx: { xs: 5, sm: 6, md: 3, lg: 0 },
          my: 1,
          borderRadius: 5,
        }}
      >
        {[
          "Data Extrication",
          "Price Modelling",
          "Specification Verification",
        ].map((title, index) => (
          <Grid
            item
            xs={12}
            ref={(el) => (paperRefs.current[index] = el)}
            key={title}
            data-index={index}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              px: { xs: 0, sm: 14 },
              my: 3,
              m: { xs: 2, md: 3 },
              borderRadius: 5,
              opacity: 0, // initially set to invisible
            }}
          >
            <Box className="paper" sx={{ width: "100%", maxWidth: 600 }}>
              <Box className="side side1" sx={{ p: 2, textAlign: "center" }}>
                <Typography
                  variant="h4"
                  sx={{
                    my: 1,
                    color: theme.palette.secondary.main,
                    fontWeight: "bold",
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  sx={{
                    mb: 3,
                    color: theme.palette.secondary.main,
                  }}
                >
                  {index === 0 && (
                    <CloudUploadIcon sx={{ fontSize: "5rem", my: 2 }} />
                  )}
                  {index === 1 && (
                    <AutorenewIcon sx={{ fontSize: "5rem", my: 2 }} />
                  )}
                  {index === 2 && (
                    <ManageSearchIcon sx={{ fontSize: "5rem", my: 2 }} />
                  )}
                </Typography>
              </Box>
              <Box className="side side2" sx={{ padding: 1.5 }}>
                <Typography variant="body2" style={{ color: "white" }}>
                  {title === "Data Extrication" &&
                    "Import and Export of boat data from publicly available sources. Our AI-driven data extraction engine can extract data from 100s of sites."}
                  {title === "Price Modelling" &&
                    "Our knowledge engine includes millions of historic and current listings, allowing pricing guidance and prediction."}
                  {title === "Specification Verification" &&
                    "Using our knowledge engine with over 15,000 makes and models, we can verify specifications and details of boats for sale, as well as using images to verify the make and model is correct."}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default KnowledgeEngineSmall;
