import React from 'react';
import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getContactUsRequests, getContactUsRequestsArchived, archiveContactUsRequest, unarchiveContactUsRequest } from '../../../requests'; 
import { useEffect } from 'react';


const ContactUsRequests = () => {
    const [requests, setRequests] = useState([])
    const [archivedRequests, setArchivedRequests] = useState([])

    const [showModal, setShowModal] = useState(false)


    useEffect(() => {
        getContactUsRequests(
            (data) => {
                setRequests(data.data)
            },
            () => {
                console.log('Failed to get contact us requests')
            }
        );
        getContactUsRequestsArchived(
            (data) => {
                setArchivedRequests(data.data)
            },
            () => {
                console.log('Failed to get archived contact us requests')
            }
        )
    },
    []
    )

    function handleArchiveRequest(contact_us_request_id) {
        console.log(contact_us_request_id)
        archiveContactUsRequest(
            {
                contact_us_request_id:contact_us_request_id
            },
            (data) => {
                console.log(data)
                setRequests(data.unarchived)
                setArchivedRequests(data.archived)
            },
            () => {
                console.log('Failed to archive contact_us request')
            }
        )
    }


    function handleUnarchiveRequest(contact_us_request_id) {
        console.log(contact_us_request_id)
        unarchiveContactUsRequest(
            {
                contact_us_request_id:contact_us_request_id
            },
            (data) => {
                console.log(data)
                setRequests(data.unarchived)
                setArchivedRequests(data.archived)
                setShowModal(false)
            },
            () => {
                console.log('Failed to unarchive contact_us request')
            }
        )
    }


    return (
        <Container fluid>
            <Row>
                <Col>
                    <div style={{marginTop: '30px'}}>
                        <h2>Contact Us Requests</h2>
                        <div>
                            <Button variant='secondary' onClick={() => {setShowModal(true)}}>
                                Archived
                            </Button>
                        </div>
                    </div>
                    {
                        requests.map((request) => {
                            return (
                                <div style={{backgroundColor: 'white', padding:'30px', marginTop:'30px'}} className='non-reactive-shadow'>
                                    <p>{request.name}</p>
                                    <p>{request.email}</p>
                                    <p>{request.phone_number}</p>
                                    <p>{request.message}</p>
                                    <div>
                                        <Button onClick={() => {handleArchiveRequest(request._id)}}>
                                            Archive
                                        </Button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Col>
            </Row>
            <Modal size='lg' show={showModal} onHide={() => {setShowModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Archived Contact Us Requests
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{backgroundColor:'RGB(220, 220, 220'}}>
                    {
                        archivedRequests.map((request) => {
                            return (
                                <div style={{backgroundColor: 'white', padding:'30px', marginTop:'30px'}} className='non-reactive-shadow'>
                                    <p>{request.name}</p>
                                    <p>{request.email}</p>
                                    <p>{request.phone_number}</p>
                                    <p>{request.message}</p>
                                    <div>
                                        <Button onClick={() => {handleUnarchiveRequest(request._id)}}>
                                            Unarchive
                                        </Button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Modal.Body>
            </Modal>

        </Container>
    );
};

export default ContactUsRequests;