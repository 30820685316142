import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SignIn from './components/SignIn'
import { useState, useEffect } from 'react'
import { fetchToken, removeToken } from '../../Auth'
import { CheckToken } from '../../requests'
import ContactUsRequests from './components/ContactUsRequests'
import KEDemoRequests from './components/KEDemoRequests'
import BrokerDemoRequests from './components/BrokerDemoRequests'
import ConsultationRequests from './components/ConsultationRequests'


export default function Admin() {

    const [signedIn, setSignedIn] = useState(false)

    useEffect(() => {
        if (fetchToken() !== null) {
            var body = {
                token: fetchToken()
            }
            CheckToken(
                body,
                (data) => {
                    setSignedIn(true)
                },
                () => {
                    setSignedIn(false)
                }
            )
        }
    }, [])

    function handleRemoveToken() {
        removeToken()
        window.location.reload()
    }

    return (
        <Container fluid>
            <Row>
                <Col style={{backgroundColor: 'rgb(220, 220, 220)', minHeight: '100vh'}} className='g-0'>
                    {
                        signedIn ?
                            <>
                                <div style={{width: '100%', backgroundColor: 'white', height: '80px', padding:'30px', paddingRight: '100px'}}>
                                    <p onClick={() => {handleRemoveToken()}} style={{color: 'red', float: 'right', cursor: 'pointer'}}>Sign Out</p>
                                </div>
                                <Container fluid>
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                                            <ContactUsRequests />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                                            <KEDemoRequests />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                                            <BrokerDemoRequests />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                                            <ConsultationRequests />
                                        </Col>
                                    </Row>
                                </Container>

                            </>
                        :
                            <SignIn />
                    }
                </Col>
            </Row>
        </Container>
    )
}
