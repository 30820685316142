
export const setToken = (token)=>{
    localStorage.setItem('super-secret-token', token)
}

export const fetchToken = ()=>{
    return localStorage.getItem('super-secret-token')
}


export const removeToken = ()=>{
    localStorage.removeItem('super-secret-token')
}





