import React from 'react';
import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getKEDemoRequests, getKEDemoRequestsArchived, archiveKEDemoRequest, unarchiveKEDemoRequest } from '../../../requests'; 
import { useEffect } from 'react';


const KEDemoRequests = () => {
    const [requests, setRequests] = useState([])
    const [archivedRequests, setArchivedRequests] = useState([])

    const [showModal, setShowModal] = useState(false)


    useEffect(() => {
        getKEDemoRequests(
            (data) => {
                setRequests(data.data)
            },
            () => {
                console.log('Failed to get knowledge engine demo requests')
            }
        );
        getKEDemoRequestsArchived(
            (data) => {
                setArchivedRequests(data.data)
            },
            () => {
                console.log('Failed to get archived knowledge engine demo requests')
            }
        )
    },
    []
    )


    function handleArchiveRequest(demo_request_id) {
        console.log(demo_request_id)
        archiveKEDemoRequest(
            {
                demo_request_id:demo_request_id
            },
            (data) => {
                console.log(data)
                setRequests(data.unarchived)
                setArchivedRequests(data.archived)
            },
            () => {
                console.log('Failed to archive KE demo request')
            }
        )
    }


    function handleUnarchiveRequest(demo_request_id) {
        console.log(demo_request_id)
        unarchiveKEDemoRequest(
            {
                demo_request_id:demo_request_id
            },
            (data) => {
                console.log(data)
                setRequests(data.unarchived)
                setArchivedRequests(data.archived)
                setShowModal(false)
            },
            () => {
                console.log('Failed to unarchive KE demo request')
            }
        )
    }



    return (
        <Container fluid>
            <Row>
                <Col>
                    <div style={{marginTop: '30px'}}>
                        <h2>KE Demo Requests</h2>
                        <div>
                            <Button variant='secondary' onClick={() => {setShowModal(true)}}>
                                Archived
                            </Button>
                        </div>
                    </div>
                    {
                        requests.map((request) => {
                            return (
                                <div style={{backgroundColor: 'white', padding:'30px', marginTop:'30px'}} className='non-reactive-shadow'>
                                    <p>{request.name}</p>
                                    <p>{request.email}</p>
                                    <p>{request.phone_number}</p>
                                    <p>{request.company}</p>
                                    <div>
                                        <Button onClick={() => {handleArchiveRequest(request._id)}}>
                                            Archive
                                        </Button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Col>
            </Row>
            <Modal size='lg' show={showModal} onHide={() => {setShowModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Archived KE Demo Requests</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        archivedRequests.map((request) => {
                            return (
                                <div style={{backgroundColor: 'white', padding:'30px', marginTop:'30px'}} className='non-reactive-shadow'>
                                    <p>{request.name}</p>
                                    <p>{request.email}</p>
                                    <p>{request.phone_number}</p>
                                    <p>{request.company}</p>
                                    <div>
                                        <Button onClick={() => {handleUnarchiveRequest(request._id)}}>
                                            Unarchive
                                        </Button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default KEDemoRequests;