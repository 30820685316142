import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { setToken, removeToken } from '../../../Auth'
import { useState } from 'react'
import { Login } from '../../../requests'


export default function SignIn() {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    
    function handleSignIn() {
        var body = {
            email: email,
            password: password
        }
        Login(
            body,
            (data) => {
                setToken(data.token)
                window.location.reload()
            },
            () => {
                removeToken()
            }
        )
    }
    
    return (
        <Container fluid>
            <Row>
                <Col>
                    <div className='non-reactive-shadow' style={{backgroundColor: 'rgb(220, 220, 220)', padding: '40px', textAlign: 'center', width: '600px', marginTop: '200px', marginLeft: 'auto', marginRight: 'auto'}}>
                        <h2 className='primary-color'>Sign In</h2>
                        <Form.Control onChange={(e) => {setEmail(e.target.value)}} style={{marginTop: '50px'}} type="email" placeholder="Email" />
                        <Form.Control onChange={(e) => {setPassword(e.target.value)}} style={{marginTop: '20px'}} type="password" placeholder="Password" />
                        <Button onClick={() => {handleSignIn()}} className='primary-bg' style={{marginTop: '50px'}} variant="primary" type="submit">
                            Submit
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
