import { BaseAPIUrl } from "./settings"



export const RequestWithBody = (url, method, body, successCallback, failedCallback) => {
    fetch(url, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    })
    .then(res => res.json())
    .then(data => {
        if (data.success === true) {
            successCallback(data)
        } else {
            failedCallback(data)
        }
    })
}


export const tokenRequiredRequest = (url, method, successCallback, failedCallback) => {
    fetch(url, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('super-secret-token')}`
        },
    })
    .then(res => res.json())
    .then(data => {
        if (data.success === true) {
            successCallback(data)
        } else {
            failedCallback(data)
        }
    })
}




export const tokenRequiredRequestWithBody = (url, method, body, successCallback, failedCallback) => {
    fetch(url, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('super-secret-token')}`
        },
        body: JSON.stringify(body)
    })
    .then(res => res.json())
    .then(data => {
        if (data.success === true) {
            successCallback(data)
        } else {
            failedCallback(data)
        }
    })
}



export const CreateContactUsRequest = (body, successCallback, failedCallback) => {
    RequestWithBody(`${BaseAPIUrl}/contact-us-request/contact-us-request`, 'POST', body, successCallback, failedCallback)
}


export const CreateRequestKEDemoRequest = (body, successCallback, failedCallback) => {
    RequestWithBody(`${BaseAPIUrl}/ke-demo-request/ke-demo-request`, 'POST', body, successCallback, failedCallback)
}


export const CreateRequestBrokerDemoRequest = (body, successCallback, failedCallback) => {
    RequestWithBody(`${BaseAPIUrl}/broker-demo-request/broker-demo-request`, 'POST', body, successCallback, failedCallback)
}


export const CreateConsultationRequest = (body, successCallback, failedCallback) => {
    RequestWithBody(`${BaseAPIUrl}/consultation-request/consultation-request`, 'POST', body, successCallback, failedCallback)
}


export const Login = (body, successCallback, failedCallback) => {
    RequestWithBody(`${BaseAPIUrl}/auth/login`, 'POST', body, successCallback, failedCallback)
}


export const CheckToken = (body, successCallback, failedCallback) => {
    RequestWithBody(`${BaseAPIUrl}/auth/check-token`, 'POST', body, successCallback, failedCallback)
}




export const getConsultationRequests = (successCallback, failedCallback) => {
    tokenRequiredRequest(`${BaseAPIUrl}/consultation-request/consultation-requests`, 'GET', successCallback, failedCallback)
}


export const getContactUsRequests = (successCallback, failedCallback) => {
    tokenRequiredRequest(`${BaseAPIUrl}/contact-us-request/contact-us-requests`, 'GET', successCallback, failedCallback)
}


export const getKEDemoRequests = (successCallback, failedCallback) => {
    tokenRequiredRequest(`${BaseAPIUrl}/ke-demo-request/ke-demo-requests`, 'GET', successCallback, failedCallback)
}


export const getBrokerDemoRequests = (successCallback, failedCallback) => {
    tokenRequiredRequest(`${BaseAPIUrl}/broker-demo-request/broker-demo-requests`, 'GET', successCallback, failedCallback)
}


export const getConsultationRequestsArchived = (successCallback, failedCallback) => {
    tokenRequiredRequest(`${BaseAPIUrl}/consultation-request/consultation-requests/archived`, 'GET', successCallback, failedCallback)
}


export const getContactUsRequestsArchived = (successCallback, failedCallback) => {
    tokenRequiredRequest(`${BaseAPIUrl}/contact-us-request/contact-us-requests/archived`, 'GET', successCallback, failedCallback)
}


export const getKEDemoRequestsArchived = (successCallback, failedCallback) => {
    tokenRequiredRequest(`${BaseAPIUrl}/ke-demo-request/ke-demo-requests/archived`, 'GET', successCallback, failedCallback)
}


export const getBrokerDemoRequestsArchived = (successCallback, failedCallback) => {
    tokenRequiredRequest(`${BaseAPIUrl}/broker-demo-request/broker-demo-requests/archived`, 'GET', successCallback, failedCallback)
}





export const archiveConsultationRequest = (body, successCallback, failedCallback) => {
    tokenRequiredRequestWithBody(`${BaseAPIUrl}/consultation-request/consultation-requests/archive`, 'POST', body, successCallback, failedCallback)
}


export const archiveContactUsRequest = (body, successCallback, failedCallback) => {
    tokenRequiredRequestWithBody(`${BaseAPIUrl}/contact-us-request/contact-us-requests/archive`, 'POST', body, successCallback, failedCallback)
}


export const archiveKEDemoRequest = (body, successCallback, failedCallback) => {
    tokenRequiredRequestWithBody(`${BaseAPIUrl}/ke-demo-request/ke-demo-requests/archive`, 'POST', body, successCallback, failedCallback)
}


export const archiveBrokerDemoRequest = (body, successCallback, failedCallback) => {
    tokenRequiredRequestWithBody(`${BaseAPIUrl}/broker-demo-request/broker-demo-requests/archive`, 'POST', body, successCallback, failedCallback)
}






export const unarchiveConsultationRequest = (body, successCallback, failedCallback) => {
    tokenRequiredRequestWithBody(`${BaseAPIUrl}/consultation-request/consultation-requests/unarchive`, 'POST', body, successCallback, failedCallback)
}


export const unarchiveContactUsRequest = (body, successCallback, failedCallback) => {
    tokenRequiredRequestWithBody(`${BaseAPIUrl}/contact-us-request/contact-us-requests/unarchive`, 'POST', body, successCallback, failedCallback)
}


export const unarchiveKEDemoRequest = (body, successCallback, failedCallback) => {
    tokenRequiredRequestWithBody(`${BaseAPIUrl}/ke-demo-request/ke-demo-requests/unarchive`, 'POST', body, successCallback, failedCallback)
}


export const unarchiveBrokerDemoRequest = (body, successCallback, failedCallback) => {
    tokenRequiredRequestWithBody(`${BaseAPIUrl}/broker-demo-request/broker-demo-requests/unarchive`, 'POST', body, successCallback, failedCallback)
}