import React from "react";
import { Box, Typography, Container, Link } from "@mui/material";
// import Logo1 from "../NewHomePagePhotos/l1.png";
import Logo2 from "../NewHomePagePhotos/l2.svg";

const Footer2 = () => {
  return (
    <Box sx={{ textAlign: "center", backgroundColor: "secondary.main" }}>
      <Box
        sx={{
          height: "20vh",
          width: "100vw",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: "20px 0",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 2,
            }}
          >
            <Typography
              variant="body1"
              sx={{ color: "quinary.main", marginX: 2 }}
            >
              Our Partners:
            </Typography>
            <Link
              href="https://www.rightboat.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box
                component="img"
                src={Logo2}
                alt="Right Boat logo"
                sx={{ height: 30, marginLeft: 2 }}
              />
            </Link>
          </Box>
          <Typography variant="h4" sx={{ color: "tertiary.main" }}>
            © 2024 All rights reserved
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer2;
