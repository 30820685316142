import React from 'react';
import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getConsultationRequests, getConsultationRequestsArchived, archiveConsultationRequest, unarchiveConsultationRequest } from '../../../requests'; 
import { useEffect } from 'react';


const ConsultationRequests = () => {
    const [requests, setRequests] = useState([])
    const [archivedRequests, setArchivedRequests] = useState([])

    const [showModal, setShowModal] = useState(false)


    useEffect(() => {
        getConsultationRequests(
            (data) => {
                setRequests(data.data)
            },
            () => {
                console.log('Failed to get consultation requests')
            }
        );
        getConsultationRequestsArchived(
            (data) => {
                setArchivedRequests(data.data)
            },
            () => {
                console.log('Failed to get archived consultation requests')
            }
        )
    },
    []
    )

    function handleArchiveRequest(consultation_request_id) {
        console.log(consultation_request_id)
        archiveConsultationRequest(
            {
                consultation_request_id:consultation_request_id
            },
            (data) => {
                console.log(data)
                setRequests(data.unarchived)
                setArchivedRequests(data.archived)
            },
            () => {
                console.log('Failed to archive consultation request')
            }
        )
    }


    function handleUnarchiveRequest(consultation_request_id) {
        console.log(consultation_request_id)
        unarchiveConsultationRequest(
            {
                consultation_request_id:consultation_request_id
            },
            (data) => {
                console.log(data)
                setRequests(data.unarchived)
                setArchivedRequests(data.archived)
                setShowModal(false)
            },
            () => {
                console.log('Failed to unarchive consultation request')
            }
        )
    }


    return (
        <Container fluid>
            <Row>
                <Col>
                    <div style={{marginTop: '30px'}}>
                        <h2>Consultation Requests</h2>
                        <div>
                            <Button variant='secondary' onClick={() => {setShowModal(true)}}>
                                Archived
                            </Button>
                        </div>
                    </div>
                    {
                        requests.map((request) => {
                            return (
                                <div style={{backgroundColor: 'white', padding:'30px', marginTop:'30px'}} className='non-reactive-shadow'>
                                    <p>{request.name}</p>
                                    <p>{request.email}</p>
                                    <p>{request.phone_number}</p>
                                    <p>{request.location}</p>
                                    <div>
                                        <Button onClick={() => {handleArchiveRequest(request._id)}}>
                                            Archive
                                        </Button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Col>
            </Row>
            <Modal size='lg' show={showModal} onHide={() => {setShowModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>
                    <Modal.Title>Archived Consultation Requests</Modal.Title>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{backgroundColor:'rgb(220, 220, 220'}}>
                    {
                        archivedRequests.map((request) => {
                            return (
                                <div style={{backgroundColor: 'white', padding:'30px', marginTop:'30px'}} className='non-reactive-shadow'>
                                    <p>{request.name}</p>
                                    <p>{request.email}</p>
                                    <p>{request.phone_number}</p>
                                    <p>{request.location}</p>
                                    <div>
                                        <Button onClick={() => {handleUnarchiveRequest(request._id)}}>
                                            Unarchive
                                        </Button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => {setShowModal(false)}}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default ConsultationRequests;