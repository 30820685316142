import { useRef, useEffect } from "react";
import anime from "animejs";
import { Container, Typography, Grid, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AssessmentIcon from "@mui/icons-material/Assessment";
import InsightsIcon from "@mui/icons-material/Insights";

const CardsLargeScreen = () => {
  const textRef8 = useRef(null);

  useEffect(() => {
    anime({
      targets: textRef8.current,
      scale: [0.8, 1],
      opacity: [0, 1],
      duration: 600,
      easing: "easeOutQuad",
      delay: 300,
    });

    anime({
      targets: ".animate-upwards1",
      translateY: [100, 0],
      opacity: [0, 1],
      duration: 1000,
      easing: "easeOutExpo",
      delay: 500,
    });

    anime({
      targets: ".animate-upwards2",
      translateY: [100, 0],
      opacity: [0, 1],
      duration: 1000,
      easing: "easeOutExpo",
      delay: 700,
    });

    anime({
      targets: ".animate-upwards3",
      translateY: [100, 0],
      opacity: [0, 1],
      duration: 1000,
      easing: "easeOutExpo",
      delay: 900,
    });

    anime({
      targets: ".animate-upwards4",
      translateY: [100, 0],
      opacity: [0, 1],
      duration: 1000,
      easing: "easeOutExpo",
      delay: 1100,
    });
  }, []);

  return (
    <Container>
      <Grid container spacing={4} sx={{ justifyContent: "center", my: 5 }}>
        <Grid item xs={10} sm={7} md={4} lg={3}>
          <Box
            className="maincontainer"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box className="thecard">
              <Box
                className="thefront animate-upwards1"
                sx={{
                  position: "relative",
                  border: "1px solid rgba(108, 145, 194, 0.1)",
                  borderRadius: "0.5em",
                  backgroundColor: "rgba(242,243,244, 0.9)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "2em",
                  minHeight: "24em",
                  textAlign: "center",
                }}
              >
                <SearchIcon
                  sx={{ fontSize: "10rem", color: "secondary.main" }}
                />
                <Typography
                  variant="h4"
                  ref={textRef8}
                  sx={{ color: "primary.main" }}
                >
                  Search Engine
                </Typography>
              </Box>
              <Box
                className="theback"
                sx={{ backgroundColor: "rgba(242,243,244, 0.9)" }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: "secondary.main", fontWeight: "bold" }}
                >
                  Get access to our proprietary Artificial Intelligence driven
                  search engine, Search over 80 sites using images and
                  requirements to find the best boats for your clients.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={10} sm={7} md={4} lg={3}>
          <Box
            className="maincontainer"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box className="thecard">
              <Box
                className="thefront animate-upwards2"
                sx={{
                  position: "relative",
                  border: "1px solid rgba(108, 145, 194, 0.1)",
                  borderRadius: "0.5em",
                  backgroundColor: "rgba(242,243,244, 0.9)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "2em",
                  minHeight: "24em",
                  textAlign: "center",
                }}
              >
                <AssessmentIcon
                  sx={{ fontSize: "10rem", color: "secondary.main" }}
                />
                <Typography
                  variant="h4"
                  ref={textRef8}
                  sx={{ color: "primary.main" }}
                >
                  Report Generation
                </Typography>
              </Box>
              <Box
                className="theback"
                sx={{ backgroundColor: "rgba(242,243,244, 0.9)" }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: "secondary.main", fontWeight: "bold" }}
                >
                  Generate reports for your clients on the fly using our report
                  builder, Add your own branding and customise the report to
                  suit your needs.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={10} sm={7} md={4} lg={3}>
          <Box
            className="maincontainer"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box className="thecard">
              <Box
                className="thefront animate-upwards3"
                sx={{
                  position: "relative",
                  border: "1px solid rgba(108, 145, 194, 0.1)",
                  borderRadius: "0.5em",
                  backgroundColor: "rgba(242,243,244, 0.9)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "2em",
                  minHeight: "24em",
                  textAlign: "center",
                }}
              >
                <AutorenewIcon
                  sx={{ fontSize: "10rem", color: "secondary.main" }}
                />
                <Typography variant="h4" sx={{ color: "primary.main" }}>
                  Client Lifecycle
                </Typography>
              </Box>
              <Box
                className="theback"
                sx={{ backgroundColor: "rgba(242,243,244, 0.9)" }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: "secondary.main", fontWeight: "bold" }}
                >
                  Manage your clients through the entire lifecycle of the boat
                  buying process, Keep track of requirements and preferences and
                  be alerted when a boat that may suit them comes up for sale.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={10} sm={7} md={4} lg={3}>
          <Box
            className="maincontainer"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box className="thecard">
              <Box
                className="thefront animate-upwards4"
                sx={{
                  position: "relative",
                  border: "1px solid rgba(108, 145, 194, 0.1)",
                  borderRadius: "0.5em",
                  backgroundColor: "rgba(242,243,244, 0.9)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "2em",
                  minHeight: "24em",
                  textAlign: "center",
                }}
              >
                <InsightsIcon
                  sx={{ fontSize: "10rem", color: "secondary.main" }}
                />
                <Typography variant="h4" sx={{ color: "primary.main" }}>
                  Market Insights
                </Typography>
              </Box>
              <Box
                className="theback"
                sx={{ backgroundColor: "rgba(242,243,244, 0.9)" }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: "secondary.main", fontWeight: "bold" }}
                >
                  Market Insights are automatically generated and avilable to
                  add to any of your clients reports
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CardsLargeScreen;
